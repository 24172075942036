import { useQuery } from '@apollo/client';

import { GET_SNOWFLAKE_BLOCK_AVAILABILITIES_TIME_WIDE } from 'graphql/snowflakeBlockAvailability';
import {
  AvailabilitiesByProperty,
  ISnowflakeBlockAvailabilityTimeWideResponse,
} from 'interfaces/ISnowflakeBlockAvailability';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';

import { LeaseRentType } from '../constants/leases';
import { PropertyPricing } from '../constants/propertyPricing';

interface Props {
  propertiesIds: number[];
  toggleOptions: LeaseToggleOptions | undefined;
}
export const useAvailabilitiesOverTimeWide = ({
  propertiesIds,
  toggleOptions,
}: Props) => {
  const { data: availabilitiesOverTimeWideData, loading } = useQuery<
    ISnowflakeBlockAvailabilityTimeWideResponse
  >(GET_SNOWFLAKE_BLOCK_AVAILABILITIES_TIME_WIDE, {
    variables: {
      propertyIds: propertiesIds,
    },
    skip: !toggleOptions || !propertiesIds.length,
  });

  const getBuildingAskingRent = (
    availabilities: AvailabilitiesByProperty[],
    propertyId: number,
    isNNN: boolean,
    toggleOptions: LeaseToggleOptions,
  ) => {
    const isRentTypeNNN = isNNN || toggleOptions.rentType === LeaseRentType.NNN;
    const isRentTimeAnnual =
      toggleOptions.timeMeasurement === PropertyPricing.ANNUAL;

    const foundAvailability = availabilities.find(
      a => a.propertyId === propertyId,
    );

    const value = isRentTimeAnnual
      ? isRentTypeNNN
        ? foundAvailability?.askingRentNNN
        : foundAvailability?.askingRentFS
      : isRentTypeNNN
      ? foundAvailability?.askingRentNNNMonthly
      : foundAvailability?.askingRentFSMonthly;
    return value ?? 0;
  };

  const getMarketAskingRent = (
    availabilities: AvailabilitiesByProperty[],
    toggleOptions: LeaseToggleOptions,
    isNNN: boolean,
    propertyId: number,
  ) => {
    const isRentTypeNNN = isNNN || toggleOptions.rentType === LeaseRentType.NNN;
    const isRentTimeAnnual =
      toggleOptions.timeMeasurement === PropertyPricing.ANNUAL;

    const foundAvailability = availabilities.find(
      a => a.propertyId === propertyId,
    );

    return isRentTimeAnnual
      ? isRentTypeNNN
        ? foundAvailability?.askingRentNNN
        : foundAvailability?.askingRentFS
      : isRentTypeNNN
      ? foundAvailability?.askingRentNNNMonthly
      : foundAvailability?.askingRentFSMonthly;
  };

  return {
    availabilitiesOverTimeWideData,
    availabilities:
      availabilitiesOverTimeWideData?.blockAvailabilitiesOverTimeWide
        .availabilitiesByProperties ?? [],
    loadingAvailabilitiesTimeWide: loading,
    getMarketAskingRent,
    getBuildingAskingRent,
  };
};
